@tailwind base;
@tailwind components;
@tailwind utilities;

face {
  font-family: "ABC Regular";
  font-style: normal;
  font-weight: normal;
  src: local("ABC Regular"),
    url("../src/styles/fonts/ABCWhyteInktrap-Regular.otf") format("opentype");
}

@font-face {
  font-family: "CosiTimes";
  font-style: normal;
  font-weight: normal;
  src: local("CosiTimes"),
    url("../src/styles/fonts/CosiTimes-Roman.otf") format("opentype");
}

@font-face {
  font-family: "Karla ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Karla-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Karla SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Karla-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Karla"),
    url("../src/styles/fonts/Karla-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: ABC Light, sans-serif;
  scrollbar-gutter: stable;
  color: white;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}